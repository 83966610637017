<template>
  <div class="divBox">
    <div class="selCard">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="85px"
      >
        <div>
          <el-form-item label="姓名：" prop="name">
            <el-input
              placeholder="请输入姓名"
              v-model="tableFrom.name"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="昵称：" prop="nickname">
            <el-input
              placeholder="请输入昵称"
              v-model="tableFrom.nickname"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="推广员姓名" prop="extensionName">
            <el-input
              placeholder="请输入推广员姓名"
              v-model="tableFrom.extensionName"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input
              placeholder="请输入手机号"
              v-model="tableFrom.phone"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="时间：" prop="time">
            <el-date-picker v-model="tableFrom.time" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item> -->
          <!-- <el-form-item label="关联门店：" prop="storeId">
            <el-select
              v-model="tableFrom.storeId"
              placeholder="请选择"
              class="selWidth"
              clearable
              filterable
            >
              <el-option
                v-for="(item, index) in storeList"
                :key="index"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item label="部门:" prop="deptId">
            <el-select
              v-model="tableFrom.deptId"
              placeholder="请选择"
              class="selWidth"
              clearable
              filterable
            >
              <el-option
                v-for="(item, index) in deptList"
                :key="index"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="用户标签:" prop="labelIds">
            <el-select
              v-model="tableFrom.labelIds"
              placeholder="请选择"
              class="selWidth"
              clearable
              filterable
              multiple
              collapse-tags
            >
              <el-option
                v-for="(item, index) in labelList"
                :key="index"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>

          <!-- <el-form-item label="身份：" prop="identity">
            <el-select
              v-model="tableFrom.identity"
              placeholder="请选择"
              class="selWidth"
              clearable

            >
              <el-option value="1" label="普通用户" />
              <el-option value="2" label="企业会员" />
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              @click="
                tableFrom.pageNum = 1;
                getList();
              "
              >搜索</el-button
            >
            <el-button size="small" @click="searchReset()">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-card class="mt14">
      <div class="mb20 marginBottom20">
        <el-button size="small" type="primary" class="mt5" @click="onAdd"
          >添加用户</el-button
        >
        <!-- <el-button size="small" type="primary" class="mt5" @click="templateExport">下载用户模板</el-button> -->
        <el-button size="small" type="primary" class="mt5" @click="importUser"
          >导入用户</el-button
        >
        <el-button
          size="small"
          type="primary"
          class="mt5"
          @click="onIntegralAll"
          >发放积分</el-button
        >
        <el-button type="primary" size="small" @click="exportFun()"
          >导出用户</el-button
        >
        <el-button type="primary" size="small" @click="exportIntegral()"
          >导出积分明细</el-button
        >
        <el-button type="primary" size="small" @click="settingLabel()"
          >设置标签</el-button
        >
        <el-button type="primary" size="small" @click="settingDept()"
          >修改部门</el-button
        >
      </div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        ref="multipleTable"
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column key="2" type="selection" width="55" />
        <el-table-column label="id" prop="userId" min-width="60">
        </el-table-column>
        <!-- <el-table-column type="index" width="50">
        </el-table-column> -->
        <el-table-column align="center" label="头像" min-width="100">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.avatarUrl ? scope.row.avatarUrl : moren"
                :preview-src-list="[scope.row.avatarUrl || moren]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="昵称"
          prop="nickname"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="姓名"
          prop="name"
          min-width="100"
        >
        </el-table-column>
        <el-table-column label="手机号" prop="phone" min-width="100">
        </el-table-column>
        <el-table-column label="部门名称" prop="deptName" min-width="100">
        </el-table-column>
        <el-table-column prop="integralDetail" label="积分账户" min-width="150">
          <template slot-scope="scope">
            <div>
              <div
                v-for="(item, index) in scope.row.integralDetail.split(';')"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="消费金额" prop="amount" min-width="100">
        </el-table-column>
        <el-table-column label="用户标签" prop="labelNames" min-width="100">
        </el-table-column>
        <el-table-column
          label="推广员姓名"
          prop="extensionName"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="extensionAgent"
          label="是否为推广员"
          min-width="100"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.extensionAgent == 1
                  ? `是(${scope.row.extensionCount})`
                  : "否"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="账户状态" min-width="100">
          <template slot-scope="scope">
            <el-switch
              @change="stateChange(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="2"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column label="门店" prop="storeName" min-width="150">
        </el-table-column> -->
        <!-- <el-table-column prop="identity" label="身份" min-width="120">
          <template slot-scope="{ row }">
            <span>{{ row.identity == 1 ? "普通用户" : "企业会员" }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" min-width="290" fixed="right">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-view"
              v-if="!scope.row.cancel_time"
              type="text"
              size="small"
              @click="onDetails(scope.row)"
              >详情</el-button
            >
            <el-button
              icon="el-icon-edit"
              v-if="!scope.row.cancel_time"
              type="text"
              size="small"
              @click="createUser(scope.row)"
              >修改积分</el-button
            >
            <el-button
              icon="el-icon-edit"
              v-if="!scope.row.cancel_time"
              type="text"
              size="small"
              @click="upLabel(scope.row)"
              >修改标签</el-button
            >
            <el-button
              icon="el-icon-delete"
              type="text"
              size="small"
              @click="userDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      title="发积分"
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="handleClose"
    >
      <div class="integra_box">
        <el-form
          ref="integraAddForm"
          size="small"
          :rules="integralAddrule"
          :model="integralAdd"
          label-width="120px"
          @submit.native.prevent
        >
          <el-form-item v-if="!isAll" label="用户积分：" prop="integralDetail">
            <div v-if="integralDetail">
              <div
                v-for="(item, index) in integralDetail.split(';')"
                :key="index"
              >
                <div>{{ item }}</div>
              </div>
            </div>
            <div v-else>暂无积分</div>
          </el-form-item>
          <el-form-item label="积分账户：" prop="integralAccountId">
            <el-select
              v-model="integralAdd.integralAccountId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in integraList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isAll" label="部门：" prop="deptId">
            <el-select
              multiple
              v-model="integralAdd.deptId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <el-cascader v-model="integralAdd.deptId" :options="departmentList" :props="departmentProps"
            clearable></el-cascader> -->
          </el-form-item>
          <el-form-item v-if="!isAll" label="类型：" prop="type">
            <el-radio-group v-model="integralAdd.type">
              <el-radio :label="2">增加</el-radio>
              <el-radio :label="1">减少</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="积分：" prop="integral">
            <el-input
              maxlength="7"
              oninput="value=value.replace(/[^\d]/g,'')"
              size="small"
              v-model="integralAdd.integral"
              placeholder="请输入"
              class="selWidth"
            >
            </el-input>
          </el-form-item>
          <el-form-item v-if="!isAll" label="备注：" prop="description">
            <el-input
              type="textarea"
              show-word-limit
              size="medium"
              :autosize="{ minRows: 5, maxRows: 10 }"
              v-model="integralAdd.description"
              placeholder="请输入"
              maxlength="200"
              class="selWidth"
            />
          </el-form-item>
          <!-- <el-form-item label="部门：" prop="phone">
          <el-cascader v-model="integraForm.deptId" :options="departmentList" :props="departmentProps"
            clearable></el-cascader>
        </el-form-item> -->
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button
          type="primary"
          :loading="showLoadingForm"
          @click="integralAddFun(integralAdd)"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!--用户详情-->
    <UserDetails
      @closeDrawer="closeDrawer"
      v-if="drawer"
      :drawer="drawer"
      :info="info"
      ref="userDetails"
    />
    <!-- 新增用户 -->
    <el-drawer
      title="添加用户"
      :size="800"
      :visible.sync="integralShow"
      direction="rtl"
      :before-close="
        () => {
          integralShow = false;
        }
      "
    >
      <el-form
        ref="integraForm"
        size="small"
        :rules="integralrule"
        :model="integraForm"
        label-width="120px"
        @submit.native.prevent
      >
        <el-form-item label="用户ID：" prop="userId">
          <el-input
            size="small"
            v-model="integraForm.userId"
            placeholder="请输入"
            maxlength="15"
            class="selWidth"
          />
        </el-form-item>
        <el-form-item label="姓名：" prop="name">
          <el-input
            size="small"
            v-model="integraForm.name"
            placeholder="请输入"
            maxlength="20"
            class="selWidth"
          />
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input
            maxlength="11"
            oninput="value=value.replace(/[^\d]/g,'')"
            :disabled="integraDisabled"
            size="small"
            v-model="integraForm.phone"
            placeholder="请输入"
            class="selWidth"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="部门：" prop="deptId">
          <el-select
            v-model="integraForm.deptId"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <el-cascader v-model="integraForm.deptId" :options="departmentList" :props="departmentProps"
            clearable></el-cascader> -->
        </el-form-item>
        <el-form-item label="是否为推广员" prop="extensionAgent">
          <el-radio-group v-model="integraForm.extensionAgent">
            <el-radio label="1">是</el-radio>
            <el-radio label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="integraForm.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="demo-drawer__footer">
        <el-button
          @click="
            () => {
              integralShow = false;
            }
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="showLoadingForm"
          @click="integralEdit(integraForm)"
          >确认</el-button
        >
      </div>
    </el-drawer>
    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />

    <el-dialog
      :title="labelTitle"
      :visible.sync="dialogLabVisible"
      width="30%"
      :before-close="labHandleClose"
    >
      <el-form>
        <el-form-item label="用户标签" prop="delivery">
          <el-select v-model="labelIds" multiple placeholder="请选择">
            <el-option
              v-for="item in labelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="labHandleClose">取 消</el-button>
        <el-button type="primary" @click="saveLabel">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="修改部门"
      :visible.sync="dialogDeptVisible"
      width="600px"
      :before-close="deptClose"
    >
      <el-form>
        <el-form-item label="部门" prop="delivery">
          <el-select
            v-model="deptId"
            placeholder="请选择"
            class="selWidth"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in deptList"
              :key="index"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deptClose">取 消</el-button>
        <el-button type="primary" @click="saveDept">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  clientUserList,
  getStoreAllList, //门店接口
  clientUserDetail,
  getDeptAllList,
} from "@/api/user/list";
import {
  clientUserAdd, //门店接口
  userDelete,
  userStateUpdate,
  deptTree,
  allIntegralAccountList,
  updateIntegral,
  sendIntegral,
  storeDeptList,
  userLabelList,
  labelUp,
  deptUpload
} from "@/api/store/storeMember";
import moment from "moment";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import UserDetails from "../member/userDetails.vue";
import Import from "./comments/import.vue";
import { exportExcel } from "@/assets/utils/exportExcel";
export default {
  name: "UserList",
  components: {
    FormImgUpload,
    UserDetails,
    Import,
  },
  data() {
    return {
      dialogLabVisible: false,
      dialogDeptVisible: false, //修改部门
      deptId:'',//单独修改部门id
      labelTitle: "设置用户标签", //设置用户标签
      labelIds: [], //用户标签的id
      labelList: [], //标签集合
      labelType: 1, //1批量设置 2 修改
      deptList: [],
      isAll: true,
      integralAdd: {
        id: "",
        integral: "",
        integralAccountId: "",
        deptId: "",
        type: 2,
        description: "",
      },
      integralAddrule: {
        integral: [
          { required: true, message: "请输入", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: "请输入正整数", trigger: "blur" },
        ],
        integralAccountId: [
          { required: true, message: "请选择积分账户", trigger: "blur" },
        ],
        deptId: [{ required: true, message: "请输入", trigger: "blur" }],
        description: [
          { required: true, message: "请输入备注", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
      },
      integraList: [],

      departmentProps: {
        multiple: false,
        label: "name",
        value: "id",
      },
      departmentList: [],

      importShow: false,
      importAction: "",
      importDownloadLink: "",
      importProblem: "",

      integralShow: false,
      loading: false, //抽屉动画
      showLoadingForm: false,
      integraObj: {}, //当前处理的积分门店
      integraDisabled: false,
      integralrule: {
        userId: [{ required: true, message: "请输入", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        phone: [{ required: true, message: "请输入", trigger: "blur" }],
        sex: [{ required: true, message: "请输入", trigger: "blur" }],
        deptId: [{ required: true, message: "请选择", trigger: "blur" }],
        extensionAgent: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
      integraForm: {
        name: "",
        phone: "",
        sex: "1",
        deptId: "",
        userId: "",
        extensionAgent: "2",
      },
      moren: require("@/assets/images/f.png"),
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        nickname: "",
        phone: "",
        time: [],
        // storeId: '',
        name: "",
        identity: "",
        extensionName: "",
      },
      groupList: [],
      visible: false,
      drawer: false,
      title: "新增用户",
      info: {},
      dialogVisible: false,
      storeList: [], //门店列表
      form: {
        image: [],
        isShow: 0,
        type: 1,
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "部门",
            prop: "deptId",
            type: "cascader",
            span: 20,
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            checkStrictly: true,
            props: { label: "name", value: "id" },
            dicData: [],
            rules: [
              {
                required: true,
                message: "请选择",
                trigger: "change",
              },
            ],
          },
          {
            label: "姓名",
            prop: "name",
            span: 20,
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机号",
            prop: "phone",
            span: 20,
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      integralDetail: "",
      selectionId: [],
    };
  },
  mounted() {
    this.getList();
    this.getdeptTree();
    this.getAllIntegralAccountList();
    // this.getStoreList();
    this.getDeptListAll();
    this.getLabelList();
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      const data = [];
      this.multipleSelection.map((item) => {
        data.push(item.id);
      });
      this.selectionId = data;
    },
    settingLabel() {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择要操作的数据");
        return;
      }
      this.getLabelList();
      this.labelType = 1;
      this.labelTitle = "设置用户标签";
      this.dialogLabVisible = true;
    },

    upLabel(row) {
      this.getLabelList();
      this.labelType = 2;
      this.labelTitle = "修改用户标签";
      this.dialogLabVisible = true;
      this.$refs.multipleTable.clearSelection();
      this.selectionId = [row.id];
      if (row.labelIds) {
        this.labelIds = row.labelIds.split(",");
      }
    },

    getLabelList() {
      userLabelList().then(({ code, data, msg }) => {
        if (code == 200) {
          this.labelList = data;
        } else {
          // this.$message.warning(msg);
        }
      });
    },
    deptClose(){
      this.deptId=''
      this.dialogDeptVisible = false;
    },
    settingDept() {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择要操作的数据");
        return;
      }
      this.dialogDeptVisible = true;
    },
    //批量修改部门 
    saveDept() {
      if(!this.deptId){
        this.$message.error("请选择部门");
        return
      }
      deptUpload({
          ids: this.selectionId,
          deptId: this.deptId,
        }).then(({ code, data, msg }) => {
          if (code == 200) {
            this.$message.success(msg);
            this.getList();
            this.deptClose()
          } else {
            // this.$message.warning(msg);
          }
        });
    },
    saveLabel() {
      if (this.labelIds.length == 0) {
        this.$message.error("请选择标签");
        return;
      }
      if (this.labelIds.length > 10) {
        this.$message.error("最多选择10个标签");
        return;
      }
      if (this.labelType == 1) {
        labelUp({
          ids: this.selectionId,
          labelIds: this.labelIds.toString(),
        }).then(({ code, data, msg }) => {
          if (code == 200) {
            this.$message.success(msg);
            this.getList();
            this.labHandleClose();
          } else {
            // this.$message.warning(msg);
          }
        });
      } else {
        labelUp({
          ids: this.selectionId,
          labelIds: this.labelIds.toString(),
        }).then(({ code, data, msg }) => {
          if (code == 200) {
            this.$message.success(msg);
            this.getList();
            this.labHandleClose();
          } else {
            // this.$message.warning(msg);
          }
        });
      }
    },
    labHandleClose() {
      this.labelIds = [];
      this.selectionId = [];
      this.$refs.multipleTable.clearSelection();
      this.dialogLabVisible = false;
    },
    //获取所有部门结构
    getDeptListAll() {
      getDeptAllList({}).then(({ code, data }) => {
        if (code == 200) {
          this.deptList = data;
        } else {
          // this.$message.warning(msg);
        }
      });
    },
    //导出
    exportFun() {
      exportExcel("/user/web/clientUser/user/store/export", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "用户列表",
      });
    },
    exportIntegral() {
      exportExcel("/user/web/clientUser/user/integral/export", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "用户积分明细列表",
      });
    },
    integralAddFun(form) {
      this.$refs.integraAddForm.validate(async (valid, done) => {
        if (valid) {
          console.log(form);
          // return
          if (this.isAll) {
            sendIntegral({
              deptId: form.deptId,
              integral: form.integral,
              integralAccountId: form.integralAccountId,
            }).then(({ code, data, msg }) => {
              if (code == 200) {
                this.$message.success("操作成功");
                this.getList();
                this.dialogVisible = false;
              } else {
                // this.$message.warning(msg);
              }
            });
          } else {
            updateIntegral({
              id: form.id,
              integral: form.integral,
              integralAccountId: form.integralAccountId,
              type: form.type,
              description: form.description,
            }).then(({ code, data, msg }) => {
              if (code == 200) {
                this.$message.success("操作成功");
                this.getList();
                this.dialogVisible = false;
              } else {
                // this.$message.warning(msg);
              }
            });
          }
          console.log(form);
          // let deptId = ''
          // if (integraForm.deptId) {
          //   deptId = integraForm.deptId[integraForm.deptId.length - 1]
          // }
          // console.log(this.integraForm);
          // this.showLoadingForm = true;
          // const { code, data } = await clientUserAdd({
          //   deptId: deptId,
          //   name: integraForm.name,
          //   phone: integraForm.phone,
          //   sex: integraForm.sex
          // })
          // if (code == 200) {
          //   this.getList();
          //   this.integralShow = false
          //   this.$message.success("操作成功");
          // }
          // this.showLoadingForm = false
        }
      });
    },
    // 获取部门树分类
    async getdeptTree() {
      // const {code, data}=await storeDeptList()
      storeDeptList({ pageNum: 1, pageSize: 99 }).then(({ code, data }) => {
        if (code == 200) {
          this.departmentList = data.records;
          console.log(data.records, "部门列表");
        }
      });
    },
    // 获取积分账户列表
    getAllIntegralAccountList() {
      allIntegralAccountList().then(({ code, data }) => {
        if (code == 200) {
          this.integraList = data;
        }
      });
    },
    importUser() {
      // console.log('批量导入房源')
      this.importAction = "/api/user/web/clientUser/import";
      this.importDownloadLink = "/api/user/web/clientUser/template/export";
      this.importProblem = "/api/user/web/clientUser/error/export";
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      if (e === 1 || e === 2) {
        this.getList();
      }
    },
    // 用户删除
    userDelete(id) {
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            userDelete(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  this.getList();
                  this.$message.success(res.msg);
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },
    // 切换门店状态
    async stateChange(item) {
      userStateUpdate({
        id: item.id,
        status: item.status,
      }).then((res) => {
        this.$message.success("操作成功");
        this.getList();
      });
    },
    // 下载模板
    // templateExport(){
    //   window.open("https://bxn.java.ahweimeng.cn/api/web/clientUser/template/export","_self");
    //   // window.open()
    // },
    // 添加
    onAdd() {
      this.showLoadingForm = false;
      this.integralShow = true;
      this.$nextTick(() => {
        this.$refs["integraForm"].resetFields();
      });
    },
    // 编辑
    integralEdit(integraForm) {
      this.$refs.integraForm.validate(async (valid, done) => {
        if (valid) {
          // let deptId = ''
          // if (integraForm.deptId) {
          //   deptId = integraForm.deptId[integraForm.deptId.length - 1]
          // }
          console.log(this.integraForm);
          this.showLoadingForm = true;
          const { code, data } = await clientUserAdd({
            deptId: integraForm.deptId,
            name: integraForm.name,
            phone: integraForm.phone,
            sex: integraForm.sex,
            userId: integraForm.userId,
            extensionAgent: integraForm.extensionAgent,
          });
          if (code == 200) {
            this.getList();
            this.integralShow = false;
            this.$message.success("操作成功");
          }
          this.showLoadingForm = false;
        }
      });
    },
    // uploadDelete() {},
    // uploadBefore(file, done, loading, column) {
    //   if (file.type === "" || column.accept.indexOf(file.type) < 0) {
    //     this.$message.error("文件格式有误");
    //     loading();
    //   } else {
    //     done();
    //   }
    // },
    // uploadError(error, column) {
    //   this.$message.error(error);
    // },
    // uploadAfter(res, done) {
    //   done();
    // },
    // upload(e) {
    //   if (Array.isArray(e)) {
    //     this.form.image = [...e];
    //   } else {
    //     this.form.image = [...this.form.image, e];
    //   }
    // },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    //获取门店接口
    getStoreList() {
      getStoreAllList({}).then((res) => {
        this.storeList = res.data;
      });
    },
    onIntegralAll() {
      this.isAll = true;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["integraAddForm"].resetFields();
      });
    },
    createUser(item) {
      this.integralDetail = item.integralDetail;
      console.log(item.integralDetail);
      this.integraForm.deptId = "";
      this.isAll = false;
      this.$nextTick(() => {
        this.$refs.integraAddForm.resetFields();
        this.integralAdd.id = item.id;
      });
      this.dialogVisible = true;
    },
    //用户详情抽屉框
    onDetails(row) {
      clientUserDetail(row.id).then((res) => {
        this.info = res.data;
        this.drawer = true;
      });
      // this.info = row;
      // this.drawer = true;
    },
    closeDrawer() {
      this.drawer = false;
    },
    getList() {
      this.listLoading = true;
      if (this.tableFrom.time.length > 0) {
        this.tableFrom.startTime = moment(this.tableFrom.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tableFrom.endTime =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.startTime = "";
        this.tableFrom.endTime = "";
      }
      clientUserList({
        ...this.tableFrom,
        time: "",
        labelIds: this.tableFrom.labelIds
          ? this.tableFrom.labelIds.toString()
          : null,
      })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    formSubmit() {},
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    handleClose() {
      // this.$refs.form.resetFields();
      // this.form.image = [];
      // this.form.isShow = 0;
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/styles/form.scss";

.check {
  color: #00a2d4;
}

.dia {
  /deep/ .el-dialog__body {
    height: 700px !important;
  }
}

.text-right {
  text-align: right;
}

.minwidth {
  display: inline-block;
  max-width: 200px;
  line-height: 20px;
}

.search-form {
  display: flex;
  justify-content: space-between;

  .search-form-box {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }

  a {
    color: var(--prev-color-primary);
  }
}

.selWidth {
  width: 250px !important;
}

.search-form-sub {
  display: flex;
}

.container {
  min-width: 821px;

  /deep/.el-form-item {
    width: 100%;
  }

  /deep/.el-form-item__content {
    width: 72%;
  }
}

.vipName {
  color: #dab176;
}

.el-dropdown-link {
  cursor: pointer;
  color: var(--prev-color-primary);
  font-size: 12px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 33.33%;
}

/deep/.el-date-editor.el-input {
  width: 100%;
}

/deep/[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: none !important;
}

/deep/.el-input-group__prepend .el-input {
  width: 100px;
}

.demo-drawer__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 1px solid #ddd;
  padding: 10px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: right;
  width: 100%;
  background: #ffffff;
}

.integra_box {
  padding-bottom: 50px;
}
</style>